import { Injectable } from '@angular/core';
import { getWindow } from '@app/shared/services/browser/window-ref.service';

export const newGuid = (): string => {
  const win = getWindow();
  let d = new Date().getTime();

  if (win.performance && typeof win.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

@Injectable({ providedIn: 'root' })
export class GuidService {
  constructor() {}

  public newGuid(): string {
    return newGuid();
  }
}
